<template>
  <div class="info-assistance relative">
    <portal to="topbar-left">
      <router-link
        :to="{ name: 'info-index' }"
        class="relative font-bold"
        key="info-assistance-back"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          class="h-6 w-6"
        >
          <g
            fill="currentColor"
            fill-rule="evenodd"
            transform="translate(-4 -1)"
          >
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"
            />
          </g>
        </svg>
      </router-link>
    </portal>
	
    <div class="mx-auto flex min-h-full flex-col space-y-4 px-4">
      <h1 class="text-3xl font-bold">{{ $t("navigation.assistance") }}</h1>
		
      <div
        class="prose max-w-none overflow-hidden break-words rounded-lg bg-white px-6 py-6 text-sm leading-normal sm:prose-sm"
      >
	  
        <h2 class="text-lg font-semibold">
          {{ $t("pages.assistance_articles.titles.search_help") }}
        </h2>
		
        <ul>
          <li>
          
            <h3 class="font-medium">
              {{ $t("pages.assistance_articles.subtitles.drug_counseling") }}
            </h3>

            <div
              v-html="$t('pages.assistance_articles.items.drug_counseling')"
            ></div>
          </li>

          <li>
            <h3 class="font-medium">
              {{ $t("pages.assistance_articles.subtitles.sexologists") }}
            </h3>

            <div
              v-html="$t('pages.assistance_articles.items.sexologists')"
            ></div>
          </li>

          <li>
            <h3 class="font-medium">
              {{ $t("pages.assistance_articles.subtitles.reference_centers") }}
            </h3>

            <div
              v-html="$t('pages.assistance_articles.items.reference_centers')"
            ></div>
          </li>

          <li>
            <h3 class="font-medium">
              {{ $t("pages.assistance_articles.subtitles.psychologists") }}
            </h3>

            <div
              v-html="$t('pages.assistance_articles.items.psychologists')"
            ></div>
          </li>
        </ul>
      </div>

      <div
        class="prose max-w-none overflow-hidden break-words rounded-lg bg-white px-6 py-6 text-sm leading-normal sm:prose-sm"
      >
        <h2>{{ $t("pages.assistance_articles.titles.listening_ear") }}</h2>

        <div v-html="$t('pages.assistance_articles.items.listening_ear')"></div>
      </div>

      <div
        class="prose max-w-none overflow-hidden break-words rounded-lg bg-white px-6 py-6 text-sm leading-normal sm:prose-sm"
      >
        <h2>{{ $t("pages.assistance_articles.titles.testing_chems") }}</h2>

        <div v-html="$t('pages.assistance_articles.items.testing_chems')"></div>
      </div>


    </div>
  </div>
</template>

<script>


	
	
import { mapGetters } from "vuex";
export default {
  name: "info-assistance",
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
  metaInfo() {
    return {
      title: `${this.$i18n.t("pages.assistance")}`,
    };
  },
};
</script>
